import React, { useState, useEffect, useCallback } from "react"
import { Form, Formik, Field } from "formik"
import * as Yup from "yup"
// import axios from "axios"
import _fetch from "../utils/_fetch"
import Radio from "../Components/Radio"
import Layout from "../Components/Layout"
import TimeElapsed from "../Components/TimeElapsed"
import Results from "../Components/Results"
import Box from "../Components/Box2"
import { RaisedButton, GhostButton, GhostButton2 } from "../Components/Buttons"
import PhoneDecoration from "../assets/images/phone-decoration.png"
import ThumbIlliustration from "../assets/images/thumb-illiustration.png"

const QUESTION_MOCK = {
  question: `1985 m. lietuvis Bronis Maigis atliko tam tikrą veiksmą,
  dėl kurio vėliau buvo pripažintas nepakaltinamu ir
  uždarytas į psichiatrinę ligoninę. 2015 m. menininkas
  Dainius Liškevičius B. Maigį ir jo atliktą veiksmą įtraukė
  į Venecijos šiuolaikinio meno bienalėje pristatytą savo
  projektą "Muziejus", kuriame veiksmą įvardino kaip
  politinį protestą. Koks tai buvo veiksmas?`,
  choices: [
    {
      id: "0",
      answer:
        'Supjaustė peiliu ir apipylė rūgštimi Ermitaže eksponuotą Rembrandto XVII a. paveikslą "Danaja"',
    },
    {
      id: "1",
      answer:
        "Išsirengė nuogai Maskvos Raudonojoje aikštėje ir prikalė savo sėklides prie grindinio",
    },
    {
      id: "1",
      answer:
        "Dėvėdamas milicinko uniformą, rugtynių Maskvoje metu įsiveržė į futbolo aikštės centrą ir ėmė rodyti nepadorius gestus.",
    },
  ],
}

const validationSchema = Yup.object().shape({
  answer: Yup.string().required("Laukas yra privalomas"),
})

export default () => {
  const [question, setQuestion] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(0)

  const getQuestion = useCallback(async questionIndex => {
    setIsLoading(true)

    _fetch(`/question/${questionIndex}`, "GET", null, true).then(({ data }) => {
      if (!data.completed) {
        setCurrentQuestion(questionIndex)
        setQuestion({
          ...data,
          // choices: data.choices.map(choice => {
          //   return {
          //     ...choice,
          //     selected: false,
          //   }
          // }),
        })
      } else {
        _fetch("/questionresults", "GET", null, true).then(
          ({ data: answers }) => {
            setCompleted({
              ...data,
              ...answers,
            })
          }
        )
      }

      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    getQuestion(currentQuestion + 1)
  }, [])

  const isError = () => {
    return question && question.status === "error"
  }

  const onSubmit = (values, { resetForm }) => {
    _fetch(
      `/question/${currentQuestion}`,
      "POST",
      {
        questionId: question.id,
        answerId: values.answer,
      },
      true
    ).then(() => {
      resetForm({})
      getQuestion(currentQuestion + 1)
    })
  }

  const onPreviousClick = () => {
    getQuestion(currentQuestion - 1)
  }

  const getInitialValues = () => {
    const answer = question.choices.find(choice => choice.selected)

    return {
      choices: question.choices,
      answer: answer ? answer.value : null,
    }
  }

  if (!question && !completed) {
    return null
  }

  const hasMedia = () => {
    return question && question.question && question.question.html
  }

  const hasMetrics = () => {
    return question && question.question && question.question.metrics
  }

  const renderTestQuestion = () => {
    return (
      <>
        <div className="test-page--progress">
          <div>
            <div>Klausimas</div>
            <div className="test-page--progress-value">
              {currentQuestion} iš {question.total}
            </div>
          </div>

          <div className="test-page--progress-track">
            <div
              className="test-page--progress-track-filler"
              style={{
                width: `${(100 * currentQuestion - 1) / question.total}%`,
              }}
            ></div>
          </div>

          <div className="tar">
            <div>Laikas</div>
            <div className="test-page--progress-value">
              <TimeElapsed startDate={question.started} />
            </div>
          </div>
        </div>
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={getInitialValues()}
          onSubmit={onSubmit}
          component={({ values }) => {
            return (
              <Form>
                <div className="text-container" style={{textAlign:"left"}}>
                  <p>{question.question.text}</p>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: question.question.metrics,
                    }}
                  ></p>

                  {hasMedia() && (
                    <div className="test-page--assets-wrapper">
                      <div className="test-page--assets">
                        {question.question.html.map((item, index) => (
                          <img
                            key={`question_asset_${values.id}_${index}`}
                            src={item}
                            alt=""
                          />
                        ))}
                      </div>

                      {/*hasMetrics() && (

                        <p
                          dangerouslySetInnerHTML={{
                            __html: question.question.metrics,
                          }}
                        ></p>
                      )*/}
                    </div>
                  )}


                </div>
                <div className="test-page--questions" style={{textAlign:"left"}}>
                  {values.choices
                    .map((item, index) => (
                      <Field
                        component={Radio}
                        required
                        defaultChecked={item.selected}
                        key={`answer_${values.id}_${index}`}
                        value={item.value}
                        name="answer"
                      >
                        {item.answer}
                      </Field>
                    ))}
                </div>

                <div className="test-page--actions">
                  <div>
                    {currentQuestion > 1 && (
                      <RaisedButton type="button" onClick={onPreviousClick}>
                        Atgal
                      </RaisedButton>
                    )}
                  </div>

                  <GhostButton2 variant="red" type="submit">
                    Kitas
                  </GhostButton2>
                </div>
              </Form>
            )
          }}
        />
      </>
    )
  }

  return (
    <Layout compact={!completed}>
      <div className="test-page">
        <Box>
          {isError() && <div className="tac">{question.message}</div>}
          {!completed &&
            !isLoading &&
            question.question &&
            renderTestQuestion()}
          {completed && (
            <Results
              answers={completed.answers_mock}
              message={completed.message}
              time={completed.results.time}
              answered={completed.results.correct}
              totalQuestions={completed.results.total}
            />
          )}
        </Box>
      </div>

      <div className="test-page--decoration tac">
        {/*
        <img src={PhoneDecoration} alt="" />
        */}
      </div>
    </Layout>
  )
}
