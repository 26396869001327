import React, { useState } from "react"
import cx from "classnames"
import ArrowBottom from "../../assets/images/arrow-bottom.png"
import BulbImg from "../../assets/images/bulb.png"

const ANSWERS_MOCK = [
  {
    question: `1985 m. lietuvis Bronis Maigis atliko tam tikrą veiksmą,
    dėl kurio vėliau buvo pripažintas nepakaltinamu ir
    uždarytas į psichiatrinę ligoninę. 2015 m. menininkas
    Dainius Liškevičius B. Maigį ir jo atliktą veiksmą įtraukė
    į Venecijos šiuolaikinio meno bienalėje pristatytą savo
    projektą "Muziejus", kuriame veiksmą įvardino kaip
    politinį protestą. Koks tai buvo veiksmas?`,
    html: [],
    metrics: "",
    correct: true,
    choices: [
      {
        id: "0",
        selected: true,
        answer:
          'Supjaustė peiliu ir apipylė rūgštimi Ermitaže eksponuotą Rembrandto XVII a. paveikslą "Danaja"',
      },
      {
        id: "1",
        correct: true,
        note:
          "Ar žinai, kad lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud laboriex ea  consequat?",
        answer:
          "Išsirengė nuogai Maskvos Raudonojoje aikštėje ir prikalė savo sėklides prie grindinio",
      },
      {
        id: "1",
        answer:
          "Dėvėdamas milicinko uniformą, rugtynių Maskvoje metu įsiveržė į futbolo aikštės centrą ir ėmė rodyti nepadorius gestus.",
      },
    ],
  },
  {
    question: `1985 m. lietuvis Bronis Maigis atliko tam tikrą veiksmą,
    dėl kurio vėliau buvo pripažintas nepakaltinamu ir
    uždarytas į psichiatrinę ligoninę. 2015 m. menininkas
    Dainius Liškevičius B. Maigį ir jo atliktą veiksmą įtraukė
    į Venecijos šiuolaikinio meno bienalėje pristatytą savo
    projektą "Muziejus", kuriame veiksmą įvardino kaip
    politinį protestą. Koks tai buvo veiksmas?`,
    html: [],
    metrics: "",
    correct: false,
    choices: [
      {
        id: "0",
        selected: true,
        answer:
          'Supjaustė peiliu ir apipylė rūgštimi Ermitaže eksponuotą Rembrandto XVII a. paveikslą "Danaja"',
      },
      {
        id: "1",
        correct: true,
        answer:
          "Išsirengė nuogai Maskvos Raudonojoje aikštėje ir prikalė savo sėklides prie grindinio",
      },
      {
        id: "1",
        answer:
          "Dėvėdamas milicinko uniformą, rugtynių Maskvoje metu įsiveržė į futbolo aikštės centrą ir ėmė rodyti nepadorius gestus.",
      },
    ],
  },
]

export default ({ message, time, answered, totalQuestions, answers }) => {
  const zeroPad = (num, places) => String(num).padStart(places, "0")

  const [accordions, setAccordions] = useState([])
  return (
    <div className="test-page--results">
      <h1 className="tac">{message}</h1>

      <div>
        <div className="test-page--results-title">Tavo rezultatai:</div>
        <div className="test-page--results-stats">
          Teisingai atsakei į <strong>{answered}</strong> iš{" "}
          <strong>{totalQuestions}</strong> klausimų. <br />
          Klausimus atsakei per <strong>{time}</strong>
          <br />

        </div>
      </div>

      {!answers && (
        <div className="text-container">
          <p>
            Sveikiname, sėkmingai sudalyvavai Nacionaliniame aplinkosaugos egzamine. <br />
          </p>
        </div>
      )}

      {answers &&
        answers.map((answer, index) => {
          return (
            <div
              key={`answer_${index}`}
              className={cx("test-page--answers", {
                correct: answer.correct,
                open: accordions.indexOf(index) > -1,
              })}
            >
              <div
                role="button"
                className="test-page--answers-header"
                onClick={() => {
                  const newAccordions = [...accordions]

                  if (accordions.indexOf(index) > -1) {
                    newAccordions.splice(accordions.indexOf(index), 1)
                  } else {
                    newAccordions.push(index)
                  }

                  setAccordions(newAccordions)
                }}
              >
                <div className="test-page--answers-number">
                  {zeroPad(index + 1, 2)}

                  <img src={ArrowBottom} alt="" />
                </div>
                <div className="test-page--answers-question">
                  {answer.question}
                </div>

                <img src={ArrowBottom} alt="" />
              </div>

              <div className="test-page--answers-content">
                <div>
                  {answer.author && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: answer.author,
                    }}
                  ></span>
                  )}
                  <br/><br/>
                  {answer.choices.map((choice, qindex) => {
                    return (
                      <React.Fragment key={`answer_${index}_${qindex}`}>
                        <div
                          className={cx("test-page--answers-item", {
                            correct: choice.correct,
                            wrong: choice.selected && !choice.correct,
                          })}
                        >
                          {choice.answer}
                        </div>
                      </React.Fragment>
                    )
                  })}

                  {answer.metrics && answer.metrics.trim() !== "" && (
                    <div className="test-page--answers-note">
                      <img src={BulbImg} alt="" />

                      <span
                        dangerouslySetInnerHTML={{
                          __html: answer.metrics,
                        }}
                      ></span>
                    </div>
                  )}
                </div>
                {answer.html && (
                  <div className="test-page--answers-content-assets">
                    {answer.html.map((asset, index) => {
                      return <img src={asset} alt="" key={index} />
                    })}
                  </div>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}
